import i18n from "i18next";
import { initReactI18next } from "react-i18next";
// import resources from "./translations/resources";
import Backend from "i18next-http-backend";

export const defaultNS = "common";

i18n.use(Backend)
    .use(initReactI18next)
    .init({
        fallbackLng: "en",
        ns: ["common"],
        defaultNS,
        interpolation: {
            escapeValue: false,
        },
        // resources,
    });
