import "antd/dist/antd.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { FC } from "react";
import { Route, Navigate, Routes } from "react-router-dom";
import loadable from "@loadable/component";

const BackToTop = loadable(() => import("./components/BackToTop/BackToTop"));
const Pages = loadable(() => import("./components/Main"));
const Demo = loadable(() => import("./components/Demo/Demo"));

const App: FC = () => {
    return (
        <div className="App">
            <Routes>
                <Route path="/" element={<Pages />} />
                <Route path="/demo" element={<Demo />} />
                <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
            <BackToTop />
        </div>
    );
};
export default App;
